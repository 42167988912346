// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ImageUpload.css */
body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}
.image-upload-container {
  display: flex;
  align-items: center;
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  flex-direction: column;
  }
  
  .image-upload-container h2 {
    margin-bottom: 20px;
  }
  
  .image-upload-container input[type="file"] {
    margin-bottom: 20px;
  }
  
  .image-upload-container button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
  }
  
  .image-upload-container button:hover {
    background-color: #0056b3;
  }

  .notText {
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/ImageUpload.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,YAAY;EACZ,SAAS;EACT,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,yDAAoD;EACpD,sBAAsB;EACtB,2BAA2B;EAC3B,sBAAsB;EACtB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,wCAAwC;IACxC,iBAAiB;IACjB,YAAY;EACd","sourcesContent":["/* ImageUpload.css */\nbody, html {\n  height: 100%;\n  margin: 0;\n  font-family: Arial, sans-serif;\n}\n.image-upload-container {\n  display: flex;\n  align-items: center;\n  height: 100vh;\n  background-image: url('../../assets/background.jpg');\n  background-size: cover;\n  background-position: center;\n  flex-direction: column;\n  }\n  \n  .image-upload-container h2 {\n    margin-bottom: 20px;\n  }\n  \n  .image-upload-container input[type=\"file\"] {\n    margin-bottom: 20px;\n  }\n  \n  .image-upload-container button {\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    border: none;\n    background-color: #007bff;\n    color: white;\n    border-radius: 5px;\n  }\n  \n  .image-upload-container button:hover {\n    background-color: #0056b3;\n  }\n\n  .notText {\n    text-align: center;\n    font-family:Arial, Helvetica, sans-serif;\n    font-weight: bold;\n    margin: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
