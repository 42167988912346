// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ImageList.css */
body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}


.image-list-container {
  display: flex;
  align-items: center;
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  flex-direction: column;
  }

  
  .image-list-container h2 {
    margin-bottom: 20px;
    
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
  
  .image-grid img {
    width: 100%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .image-grid img:hover {
    border-color: #007bff;
  }
  
  .image-item button {
  
    padding: 5px 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left:15px;
  }
  
  .image-item button:hover {
    background-color: darkred;
  }

  .geriDon{
    padding: 10px;
    background-color: #ec1b1b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self:center;
    margin: 5px;
  }

.geriDon:hover {
    background-color: #841f09;
  }
  
 `, "",{"version":3,"sources":["webpack://./src/pages/ImageList.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,YAAY;EACZ,SAAS;EACT,8BAA8B;AAChC;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,yDAAoD;EACpD,sBAAsB;EACtB,2BAA2B;EAC3B,sBAAsB;EACtB;;;EAGA;IACE,mBAAmB;;EAErB;;EAEA;IACE,aAAa;IACb,4DAA4D;IAC5D,SAAS;EACX;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;;IAEE,iBAAiB;IACjB,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,WAAW;EACb;;AAEF;IACI,yBAAyB;EAC3B","sourcesContent":["/* ImageList.css */\nbody, html {\n  height: 100%;\n  margin: 0;\n  font-family: Arial, sans-serif;\n}\n\n\n.image-list-container {\n  display: flex;\n  align-items: center;\n  height: 100vh;\n  background-image: url('../../assets/background.jpg');\n  background-size: cover;\n  background-position: center;\n  flex-direction: column;\n  }\n\n  \n  .image-list-container h2 {\n    margin-bottom: 20px;\n    \n  }\n  \n  .image-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));\n    gap: 10px;\n  }\n  \n  .image-grid img {\n    width: 100%;\n    height: auto;\n    border: 2px solid #ddd;\n    border-radius: 5px;\n    object-fit: cover;\n  }\n  \n  .image-grid img:hover {\n    border-color: #007bff;\n  }\n  \n  .image-item button {\n  \n    padding: 5px 10px;\n    background-color: red;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-left:15px;\n  }\n  \n  .image-item button:hover {\n    background-color: darkred;\n  }\n\n  .geriDon{\n    padding: 10px;\n    background-color: #ec1b1b;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    align-self:center;\n    margin: 5px;\n  }\n\n.geriDon:hover {\n    background-color: #841f09;\n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
