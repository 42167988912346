import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminEntry.css';
import karamanAltin from '../assets/karamanAltin.png';
import { jwtDecode } from 'jwt-decode';

const labels = {
  bilezikFiyat: '22 Ayar Bilezik',
  ataLiraFiyat: 'Ata Lira',
  hamitLiraFiyat: 'Hamit Lira',
  cumhuriyetLiraFiyat: 'Cumhuriyet Lira',
  yarimFiyat: 'Yarım Altın',
  ceyrekFiyat: 'Çeyrek Altın',
  yarimEskiFiyat: 'Yarım Altın (Eski)',
  ceyrekEskiFiyat: 'Çeyrek Altın (Eski)',
  cumhuriyetLiraEskiFiyat: 'Cumhuriyet Lira (Eski)',
};

const labels2 = {
  ondortk: '575',
  yirmiikik: '750',
  yirmidortk: '1000',
};

const initialPrices = {
  bilezikFiyat: '',
  bilezikFiyat_: '',
  hamitLiraFiyat: '',
  hamitLiraFiyat_: '',
  ataLiraFiyat: '',
  ataLiraFiyat_: '',
  cumhuriyetLiraFiyat: '',
  cumhuriyetLiraFiyat_: '',
  cumhuriyetLiraEskiFiyat: '',
  cumhuriyetLiraEskiFiyat_: '',
  yarimFiyat: '',
  yarimFiyat_: '',
  yarimEskiFiyat: '',
  yarimEskiFiyat_: '',
  ceyrekFiyat: '',
  ceyrekFiyat_: '',
  ceyrekEskiFiyat: '',
  ceyrekEskiFiyat_: '',
 
};

const initialPrices2 = {
  ondortk: '',
  yirmiikik: '',
  yirmidortk: '',
};

export const AdminEntry = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [prices, setPrices] = useState(initialPrices);
  const [prices2, setPrices2] = useState(initialPrices2);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      setIsLoggedIn(true);
      setUsername(getUserNameFromToken(token));
      fetchPrices(token);
    }
  }, [navigate]);

  const fetchPrices = async (token) => {
    try {
      const response = await fetch('https://api.karamanaltinfiyatlari.com/api/prices', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPrices(data);
      } else {
        throw new Error('Unable to fetch prices');
      }
    } catch (error) {
      console.error('Error fetching prices:', error);
      navigate('/login');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      if (initialPrices.hasOwnProperty(name)) {
        setPrices({
          ...prices,
          [name]: value,
        });
      } else if (initialPrices2.hasOwnProperty(name)) {
        setPrices2({
          ...prices2,
          [name]: value,
        });
      }
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const getUserNameFromToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded.username || 'User';
    } catch (error) {
      console.error('Error decoding token:', error);
      return 'User';
    }
  };

  const vazgec = () => {
    navigate('/', { replace: true });
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://api.karamanaltinfiyatlari.com/api/prices?date=${selectedDate}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const updatedPrices = { ...data, ...prices, ...prices2, date: selectedDate };
  
        await fetch('https://api.karamanaltinfiyatlari.com/api/prices', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(updatedPrices),
        });
  
        setAlertMessage('Fiyat Güncelleme Başarılı!');
        setAlertType('success');
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setAlertMessage('Bir sıkıntı oluştu, tekrar deneyiniz');
      setAlertType('error');
    }
  
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleClickUpload = () => {
    navigate('/upload');
  };

  const handleClickImages = () => {
    navigate('/images');
  };

  const handleClickRecords = () => {
    navigate('/tumfiyatlar');
  };

  const handleClickUserManagement = () => {
    navigate('/user-management');
  };

  return (
    <div className="App">
      <img onClick={vazgec} className="logo" src={karamanAltin} alt="Açıklama" />
      {isLoggedIn && <h1 style={{ margin: '15px' }}>Merhaba {username}!</h1>}
      <div style={{ display: 'flex' }}>
        <button style={{ margin: 5 }} className='menuButton' onClick={handleClickUpload}>Görsel Yükle</button>
        <button style={{ margin: 5 }} className='menuButton' onClick={handleClickImages}>Galeri</button>
      </div>
      <div style={{ display: 'flex' }}>
        <button style={{ margin: 5 }} className='menuButton' onClick={handleClickUserManagement}>Kullanıcı İşlemleri</button>
        <button style={{ margin: 5 }} className='menuButton' onClick={handleClickRecords}>Altın Kayıtları</button>
      </div>
      {isLoggedIn ? (
        <div className="card">
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="datePicker">Lütfen Tarih Seçiniz:</label>
            <input
              type="date"
              id="datePicker"
              value={selectedDate}
              onChange={handleDateChange}
              className="custom-input"
            />
          </div>
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Alış</th>
                <th>Satış</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(initialPrices).map((key, index) => {
                if (index % 2 === 0) {
                  const nextKey = Object.keys(initialPrices)[index + 1];
                  return (
                    <tr key={index}>
                      <td>
                        <th>{labels[key]}</th>
                        <input
                          type="text"
                          name={key}
                          placeholder="Yeni Fiyatı Giriniz"
                          value={prices[key] || ''}
                          onChange={handleInputChange}
                          className="custom-input"
                        />
                      </td>
                      {nextKey && (
                        <td>
                          <th>{labels[nextKey]}</th>
                          <input
                            type="text"
                            name={nextKey}
                            placeholder="Yeni Fiyatı Giriniz"
                            value={prices[nextKey] || ''}
                            onChange={handleInputChange}
                            className="custom-input"
                          />
                        </td>
                      )}
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
          <h2>Külçe Fiyat</h2>
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Ayar</th>
                <th>Fiyat</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(initialPrices2).map((key, index) => (
                <tr key={index}>
                  <td>
                    <th>{labels2[key]}</th>
                  </td>
                  <td>
                    <input
                      type="text"
                      name={key}
                      placeholder="Yeni Fiyatı Giriniz"
                      value={prices2[key] || ''}
                      onChange={handleInputChange}
                      className="custom-input"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <button style={{ backgroundColor: 'red' }} onClick={handleLogout}>Çıkış Yap</button>
            <button onClick={handleUpdate} style={{ backgroundColor: 'green' }}>Güncelle</button>
          </div>
          {alertMessage && (
            <div className={`alert ${alertType}`}>
              <p>{alertMessage}</p>
            </div>
          )}
        </div>
      ) : (
        <h1 style={{ marginBottom: '15px' }}>ADMİN GİRİŞ PANELİ</h1>
      )}
    </div>
  );
};

