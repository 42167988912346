// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-rotator-container {
    flex:none;
    width: 300px;
    height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    margin: 20px auto;
  }
  
  .rotating-image {
    width: 300px;
    height: 300px;
    object-fit: cover; /* Görüntünün kırpılması */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/ImageRotator.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,iBAAiB,EAAE,0BAA0B;EAC/C","sourcesContent":[".image-rotator-container {\n    flex:none;\n    width: 300px;\n    height: 300px;\n    overflow: hidden;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid #ccc;\n    margin: 20px auto;\n  }\n  \n  .rotating-image {\n    width: 300px;\n    height: 300px;\n    object-fit: cover; /* Görüntünün kırpılması */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
